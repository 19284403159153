export function formatUsage(usage: any) {
  const usageNumber = Number(usage);

  if (isNaN(usageNumber)) {
    return '-';
  } else if (usageNumber > 1000000000) {
    return `${Math.round(usageNumber / 1000000000)} TB`;
  } else if (usageNumber > 1000000) {
    return `${Math.round(usageNumber / 1000000)} GB`;
  } else if (usageNumber > 1000) {
    return `${Math.round(usageNumber / 1000)} MB`;
  }
  return `${Math.round(usageNumber)} KB`;
}
